import {exists, Module} from '@nu-art/ts-common';
import {Default_SyncManagerNodePath, ModuleFE_SyncManager, Thunder} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_AdvisorSession} from '@app/advisor/_entity/advisor-session/frontend';
import {SyncDataFirebaseState} from '@nu-art/thunderstorm/shared/sync-manager/types';
import {ModuleFE_Advisor, OnAdvisorReset} from '@app/advisor/frontend';

class ModuleFE_AppModule_Class
	extends Module implements OnAdvisorReset {

	private firstLaunchCompleted?: boolean;

	__onAdvisorReset() {
		this.firstLaunchCompleted = false;
	}

	protected init() {
		super.init();


		// set sync manager dynamic resolvers
		ModuleFE_SyncManager.setNodeContext(() => {
			const encounterId = ModuleFE_AdvisorSession.getCurrentEncounterId();

			if (!encounterId)
				return Default_SyncManagerNodePath;

			return `/state/DataSyncManager/${encounterId}/syncData`;
		});

		ModuleFE_SyncManager.setOnSyncCompleted(this.isAppLoading); // app will stop being busy only when sync is completed
		ModuleFE_SyncManager.setOnSyncDataChanged(this.isLoadingInitialData); // this process might end beforehand

		// advisor must sync faster
		ModuleFE_SyncManager.setDebounceTimeout(500);
		ModuleFE_SyncManager.setDebounceMaxTimeout(1500);

		ModuleFE_SyncManager.setSmartSyncUrl(`${Thunder.getInstance().getConfig().encounterManagerBackend}/data-sync-manager/smart-sync`);
	}

	private isAppLoading = (syncData: SyncDataFirebaseState | undefined) => {
		this.logDebug('Got sync data', syncData);
		const _syncData = syncData as SyncDataFirebaseState & { isBusy: boolean };

		if (!exists(_syncData?.isBusy))
			return;

		ModuleFE_Advisor.setReadOnly(_syncData.isBusy);


		if (_syncData.isBusy || ModuleFE_Advisor.isAvatarMode() || this.firstLaunchCompleted)
			return;

		// mark first launch completed in memory and get the available protocols
		this.firstLaunchCompleted = true;
		ModuleFE_Advisor.vv1.protocols.getAvailableProtocols().execute();
	};

	private isLoadingInitialData = (syncData: SyncDataFirebaseState | undefined) => {
		if (!syncData)
			return;

		this.logDebug('sync data updated', syncData);
		const _syncData = syncData as SyncDataFirebaseState & { loadingInitialData: boolean };
		if (exists(_syncData?.loadingInitialData))
			ModuleFE_Advisor.setLoadingInitialData(_syncData.loadingInitialData);
	};

}

export const ModuleFE_AppModule = new ModuleFE_AppModule_Class();