import * as React from 'react';
import {
	ATS_TextArea,
	AppToolsScreen,
	ComponentSync,
	ModuleFE_SyncManager_CSV,
	TS_AppTools,
	TS_Route,
	ATS_CollectionUpgrades
} from '@nu-art/thunderstorm/frontend';
import {Outlet} from 'react-router-dom';
import {ATS_ButtonGroup, ATS_DropDown, ATS_Icons, ATS_Input, ATS_ScrollBar, ATS_ScrollFade} from '@app/styles/frontend';
import {ATS_AdvisorPanel, ATS_Page_Advisor} from '@app/advisor-v5/frontend/ui';
import {ATS_Button} from '@app/styles/frontend/ui/components/Button/ATS_Button';
import {ATS_DataFieldV2} from '@app/advisor-v5/frontend/ui/components/Renderer_DataFieldV2/ATS_DataFieldV2';
import {
	ATS_AdvisorErrorBoundary
} from '@app/advisor-v5/frontend/ui/components/Advisor_ErrorBoundary/ATS_AdvisorErrorBoundary';
import {ATS_Toggle} from '@nu-art/thunderstorm/frontend/components/TS_Toggle';
import {ATS_ZippedCSV} from '@apps/artifact-ats-frontend/ats/ATS_ZippedCSV';

const appToolsScreens: AppToolsScreen[] = [
	ATS_ScrollBar.Screen,
	ATS_Input.Screen,
	ATS_Icons.Screen,
	ATS_DropDown.Screen,
	ATS_ScrollFade.Screen,
	ATS_AdvisorPanel.Screen,
	ATS_Button.Screen,
	ATS_ButtonGroup.Screen,
	ATS_Page_Advisor.Screen,
	ATS_DataFieldV2.Screen,
	ATS_AdvisorErrorBoundary.Screen,
	ATS_TextArea.Screen,
	ATS_CollectionUpgrades.screen,
	ATS_Toggle.Screen,
	ATS_ZippedCSV.screen,
];

export class DevPage
	extends ComponentSync {

	static Route: TS_Route = {
		key: 'app-tools-wrapper',
		path: 'dev',
		children: [{
			...TS_AppTools.createRoute(appToolsScreens, 'app-tools'),
			index: true,
		}],
		Component: this,
	};

	componentDidMount() {
		//HACK HACK HACK - ready modules so we can use them in app tools screens!
		ModuleFE_SyncManager_CSV.readyAllModules();
	}

	render() {
		return <Outlet/>;
	}
}